const device = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPod|iPad/i);
  },
  any() {
    return !!(device.Android() || device.iOS());
  },
};

const isMobile = typeof navigator !== 'undefined' ? device.any() : true;
export { device, isMobile };
