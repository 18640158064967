import React from 'react';
import About from './About';
import Specialization from './Specialization';
import Skills from './Skills';
import Projects from './Projects';
import Recommendations from './Recommendations';
import Timeline from './Timeline';
import Contact from './Contact';
import commandDescription from '../../common/terminal/commandDescription';
import WelcomeMessage from '../../common/WelcomeMessage';
import Clear from "./Clear";

const about = {
  description: commandDescription.ABOUT,
  fn: () => {
    return <About />;
  },
};

const specialization = {
  description: commandDescription.SPECIALIZATION,
  fn: () => {
    return <Specialization />;
  },
};

const skills = {
  description: commandDescription.SKILLS,
  fn: () => {
    return <Skills />;
  },
};

const projects = {
  description: commandDescription.PROJECTS,
  fn: () => {
    return <Projects />;
  },
};

const recommendations = {
  description: commandDescription.RECOMMENDATIONS,
  fn: () => {
    return <Recommendations />;
  },
};

const timeline = {
  description: commandDescription.TIMELINE,
  fn: () => {
    return <Timeline />;
  },
};

const contact = {
  description: commandDescription.CONTACT,
  fn: () => {
    return <Contact />;
  },
};

const help = terminalRef => {
  return {
    description: commandDescription.HELP,
    fn: () => {
      return <WelcomeMessage terminalRef={terminalRef} />;
    },
  };
};

const clear = terminalRef => ({
  description: commandDescription.CLEAR,
  fn: () => {
    return <Clear terminalRef={terminalRef} />;
  },
});

export default terminalRef => ({
  about,
  specialization,
  skills,
  projects,
  recommendations,
  timeline,
  contact,
  help: help(terminalRef),
  clear: clear(terminalRef),
});
