import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const { allTimelineJson } = useStaticQuery(graphql`
    {
      allTimelineJson {
        edges {
          node {
            work {
              company_name
              role
              type
              tenure {
                start
                end
              }
              summary
            }
            education {
              institution_name
              degree
              tenure {
                start
                end
              }
            }
          }
        }
      }
    }
  `);

  const { work, education } = allTimelineJson.edges[0].node;

  return (
    <div className="text-sm">
      <div className="pt-4 pb-2 text-xl font-medium uppercase">Work & Education</div>

      {work.map(w => {
        const { company_name: companyName, role, tenure, summary } = w;
        return (
          <div key={companyName} className="v-timeline">
            <div className="v-timeline-event">
              <div className="v-timeline-icon">&nbsp;</div>
              <div className="v-timeline-copy pt-8 md:px-4">
                <div className="v-timeline-thumbnail inline-block py-1 px-4 mb-5 text-base text-olivenite font-medium bg-golden_gun">
                  {tenure.start} - {tenure.end}
                </div>
                <div className="text-2xl text-yellow">{companyName}</div>
                <div className="text-lg pt-1">{role}</div>
                <div className="py-4 whitespace-pre-line">{summary}</div>
              </div>
            </div>
          </div>
        );
      })}

      {education.map(e => {
        const { institution_name: institutionName, degree, tenure } = e;
        return (
          <div key={institutionName} className="v-timeline">
            <div className="v-timeline-event">
              <div className="v-timeline-icon">&nbsp;</div>
              <div className="v-timeline-copy pt-8 md:px-4">
                <div className="v-timeline-thumbnail inline-block py-1 px-4 mb-5 text-base text-olivenite font-medium bg-golden_gun">
                  {tenure.start} - {tenure.end}
                </div>
                <div className="text-2xl text-yellow">{institutionName}</div>
                <div className="text-lg pt-2">{degree}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
