import React from 'react';

export default () => (
  <div className="text-sm">
    <div className="py-4 text-xl font-medium uppercase">Specializing in</div>

    <div className="pb-2">
      <div className="py-1 text-base text-yellow font-semibold">Anything web!</div>
      <div className="pl-5">Architecture, Development and Performance</div>
    </div>

    <div className="py-2">
      <div className="py-1 text-base text-yellow font-semibold">UI/UX</div>
      <div className="pl-5">Design system, User Flow, Wireframing and Prototyping</div>
    </div>

    <div className="py-2">
      <div className="py-1 text-base text-yellow font-semibold">Team Management</div>
      <div className="pl-5">Hiring, Mentoring and Performance Management</div>
    </div>

    <div className="py-2">
      <div className="py-1 text-base text-yellow font-semibold">SEO</div>
      <div className="pl-5">Audit, Implementation and Improve SERP</div>
    </div>

    <div className="py-2">
      <div className="py-1 text-base text-yellow font-semibold">Product Management</div>
      <div className="pl-5">Assessment, Strategy, Roadmap and Outcome Metric</div>
    </div>
  </div>
);
