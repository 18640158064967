import React from 'react';
import commandDescription from './terminal/commandDescription';

const CommandList = cprops => {
  const commandProps = { ...cprops };
  const { command, handleClick } = commandProps;

  return (
    <div className="pt-2" onClick={() => handleClick(command)} role="button" tabIndex={0}>
      <span>{command.toLowerCase()}</span>
    </div>
  );
};

export default props => {
  const messageProps = { ...props };
  const { terminalRef: terminal } = messageProps;
  const commands = Object.keys(commandDescription);

  const handleClick = command => {
    const terminalInput = terminal.current.terminalInput.current;
    terminalInput.value = command.toLowerCase();
    setTimeout(() => {
      const ke = new KeyboardEvent('keydown', {
        bubbles: true,
        cancelable: true,
        keyCode: 13,
      });
      terminalInput.dispatchEvent(ke);
    }, 100);
  };

  return (
    <div className="pb-4">
      <div className="py-2">Enter one of these commands to know more:</div>
      {commands.map(command => (
        <CommandList key={command} command={command} handleClick={handleClick} />
      ))}
    </div>
  );
};
