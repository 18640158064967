import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import TwitterIcon from '../../../static/assets/contact-icons/twitter.svg';
import LinkedInIcon from '../../../static/assets/contact-icons/linkedin.svg';
import InstagramIcon from '../../../static/assets/contact-icons/instagram.svg';
import EmailIcon from '../../../static/assets/contact-icons/email.svg';

export default () => {
  const { contactDetailsJson } = useStaticQuery(graphql`
    {
      contactDetailsJson {
        twitter
        linkedIn
        instagram
        email
      }
    }
  `);

  const { twitter, linkedIn, instagram, email } = contactDetailsJson;

  return (
    <div className="text-sm">
      <div className="py-4 text-xl font-medium uppercase">Contact</div>

      <div className="flex">
        <a href={`mailto:${email}`} target="_blank" rel="nofollow noreferrer" className="pr-2">
          <img src={EmailIcon} alt="email" width={50} className="ct-icn-email" />
        </a>

        <a href={twitter} target="_blank" rel="nofollow noreferrer" className="pr-2">
          <img src={TwitterIcon} alt="twitter" width={36} />
        </a>
        <a href={linkedIn} target="_blank" rel="nofollow noreferrer" className="pr-2">
          <img src={LinkedInIcon} alt="linkedIn" width={36} />
        </a>
        <a href={instagram} target="_blank" rel="nofollow noreferrer" className="pr-2">
          <img src={InstagramIcon} alt="instagram" width={36} />
        </a>
      </div>
    </div>
  );
};
