/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import TwitterIcon from '../../static/assets/info/twitter.svg';
import TwitterDarkIcon from '../../static/assets/info/twitter-dark.svg';

const InfoText = () => {
  const [hover, setHover] = useState(false);

  return (
    <div className="px-3 py-10 border-t-8 border-pacifika_light sm:border-transparent sm:mt-32">
      <div className="uppercase lg:text-xl pb-2">Hello,</div>
      <div className="pb-2 text-3xl sm:text-2xl md:text-4xl lg:text-5xl text-yellow">I&apos;m Shivam Rastogi</div>
      <div className="pb-3 sm:pb-4 text-sm lg:text-base">Architecting web apps since 2010</div>

      <a
        href="https://twitter.com/shivamsupr"
        className="mx-3 sm:mx-0 w-44 flex justify-center px-6 py-3
      border border-yellow
      hover:bg-yellow
      text-yellow hover:text-alligator_gladiator
      transform -skew-x-12"
        rel="nofollow noreferrer"
        target="_blank"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <span>Let&apos;s talk</span>&nbsp;
        <span>
          <img src={hover ? TwitterDarkIcon : TwitterIcon} alt="twitter" width={20} height={20} />
        </span>
      </a>
    </div>
  );
};

export default InfoText;
