import React from 'react';

export default () => (
  <div className="text-sm">
    <div className="py-4 text-xl font-medium uppercase">About me</div>

    <div className="pb-2">
      My hometown is a small town named{' '}
      <a className="font-semibold text-yellow text-base" href="https://en.wikipedia.org/wiki/Kaimganj" target="_blank" rel="noreferrer">
        Kaimganj
      </a>{' '}
      in Uttar Pradesh, India. I have always been an average student across my educational background. I was good at science and won many
      awards in inter-college science competitions. Also, I liked playing all sports, but Cricket was love, actually still is. I completed
      my basic education in Kaimganj and moved to Mumbai to complete my undergrad degree.
    </div>

    <div className="py-2">
      I always wanted to be an engineer since I was eight. As a young boy, I used to love repairing a lot of home appliances (even though
      I&apos;d broken most of the stuff), and I was the cycle repairing ninja for my Mohalla kids. After seeing my passion, my family
      allowed me to learn repairing (I forced them a lot. :p) professionally in next summer vacations.
    </div>

    <div className="py-2">
      The Computers were not so common in small town India around 1999-2000 and my elder sister asked me to enrol in a local institute where
      they were teaching Microsoft Windows 95, 98 and Microsoft Office and a few programming languages like{' '}
      <a className="font-semibold text-yellow text-base" href="https://en.wikipedia.org/wiki/Visual_Basic" target="_blank" rel="noreferrer">
        Visual Basic
      </a>
      ,{' '}
      <a
        className="font-semibold text-yellow text-base"
        href="https://en.wikipedia.org/wiki/Logo_(programming_language)"
        target="_blank"
        rel="noreferrer"
      >
        LOGO
      </a>
      ,{' '}
      <a className="font-semibold text-yellow text-base" href="https://en.wikipedia.org/wiki/COBOL" target="_blank" rel="noreferrer">
        COBOL
      </a>
      ,{' '}
      <a className="font-semibold text-yellow text-base" href="https://en.wikipedia.org/wiki/FoxPro" target="_blank" rel="noreferrer">
        FoxPro
      </a>
      , etc. and tools like{' '}
      <a className="font-semibold text-yellow text-base" href="https://en.wikipedia.org/wiki/CorelDRAW" target="_blank" rel="noreferrer">
        CorelDRAW
      </a>
      ,{' '}
      <a
        className="font-semibold text-yellow text-base"
        href="https://en.wikipedia.org/wiki/Adobe_Photoshop"
        target="_blank"
        rel="noreferrer"
      >
        Adobe Photoshop
      </a>
      ,{' '}
      <a
        className="font-semibold text-yellow text-base"
        href="https://en.wikipedia.org/wiki/Tally_Solutions"
        target="_blank"
        rel="noreferrer"
      >
        Tally
      </a>
      , etc.{' '}
    </div>

    <div className="py-2">
      While I was learning all that jazz, a new professor joined and started training people on HTML. I wanted to learn it, but my professor
      said no. His words were &quot;Tu bahut chhota hai HTML seekhne ke liye&quot;. I was 10 years old then. I was a stubborn kid, I asked
      one of my senior friends to show me what they teach and took his notes home, and the very next day I created the HTML page that I
      learned from his notes. My professor was highly impressed with that, he allowed me to learn it as well. That&apos;s when I fell in
      love with the computers (and the web) and thought I&apos;ll be a software engineer one day.
    </div>

    <div className="py-2">
      In 2012 I graduated from KC College of engineering in Thane, Mumbai with a B.E. in Computer Science. Initially, it was hard for me as
      I had taken a year gap post 12th standard and my medium of instruction was Hindi all this while, and in engineering, all books were in
      English. But after a lot of struggle and hurdles, I started enjoying it. I was the head of the tech in my final year of college. Those
      four years in college, taught me a lot. How to not give up and rise after every fall and be happy no matter what you&apos;re going
      through inside.{' '}
    </div>

    <div className="py-2">
      Though I had good programming skill, I didn&apos;t get work right after college. The struggle didn&apos;t end there. Many businesses
      that went to college for placements employed top rank students, and only a few opportunities were open for students such as myself.{' '}
    </div>

    <div className="py-2">
      But I decided to look for a job outside the college. It took me nine months for my first post-college job. In those nine months, I
      solved several coding challenges and even coded my friends&apos; college tasks for their Master&apos;s degree. During this period, I
      was also set back by a back injury (that continues to recur sometimes) which confined me to bed rest for 3 months. Then, in one month,
      I did 24 non-stop interviews and got my first job.
    </div>

    <div className="py-2">
      I joined{' '}
      <a className="font-semibold text-yellow text-base" href="https://www.pricebaba.com" target="_blank" rel="noreferrer">
        Pricebaba.com
      </a>
      , a young startup, then, at a salary of Rs. 7,000 (~$120 that time) per month, they offered me the position of a software developer. I
      was hired as a Java developer to work on an{' '}
      <a className="font-semibold text-yellow text-base" href="http://nutch.apache.org/" target="_blank" rel="noreferrer">
        Apache Nutch
      </a>
      -based web crawler project, but since I was the only dev other than the CTO, they asked me to work on all PHP-written web
      applications. I&apos;ve always been a Java dev. I didn&apos;t have any other choice than to learn and adapt.
    </div>
    <div className="py-2">
      During my days at Pricebaba, I learned a lot from how to read, write and debug the code, how to handle the entire technology stack
      from the Front-end to the Back-end to the DevOps, how to act, speak, and present yourself. It was another institution for me. I also
      learned a lot from colleagues, particularly the main guy{' '}
      <a className="font-semibold text-yellow text-base" href="https://twitter.com/annkur" target="_blank" rel="noreferrer">
        Annkur
      </a>
      ,{' '}
      <a className="font-semibold text-yellow text-base" href="https://twitter.com/annkur" target="_blank" rel="noreferrer">
        co-founder, Pricebaba
      </a>
      , and{' '}
      <a className="font-semibold text-yellow text-base" href="https://twitter.com/pateldhruv05" target="_blank" rel="noreferrer">
        Dhruv
      </a>
      , my manager at that time.
    </div>

    <div className="py-2">
      By the time I moved on 3 yrs and 9 months later, my salary had grown 12X!. It was as tough to leave, tougher; than a heartbreak. But
      eventually, like all break-ups you move on. :p
    </div>

    <div className="py-2">
      After that, I started consulting young startups on the web stack. I did that for almost a year, I learnt a lot even when I was
      consulting. I had always been an introvert but consulting helped me transform to almost an extrovert.
    </div>

    <div className="py-2">
      Whilst I was consulting, I was fortunate enough to meet two more gems,{' '}
      <a className="font-semibold text-yellow text-base" href="https://twitter.com/_svs_" target="_blank" rel="noreferrer">
        Sid
      </a>{' '}
      and{' '}
      <a className="font-semibold text-yellow text-base" href="https://twitter.com/RohitTikmany" target="_blank" rel="noreferrer">
        Rohit
      </a>
      . I met Sid first. He saw my passion and offered me a consulting job at{' '}
      <a className="font-semibold text-yellow text-base" href="https://www.shaadi.com" target="_blank" rel="noreferrer">
        shaadi.com
      </a>{' '}
      after I tanked in the job interview (according to the person who took my interview back then). I met Rohit there, worked with him for
      a month as a consultant. They saw my potential, trusted my skills and offered me a full-time job as an engineering manager to lead the
      entire engineering of a brand new product{' '}
      <a className="font-semibold text-yellow text-base" href="https://www.sangam.com" target="_blank" rel="noreferrer">
        Sangam.com
      </a>{' '}
      (
      <a className="font-semibold text-yellow text-base" href="https://www.familyshaadi.com" target="_blank" rel="noreferrer">
        Familyshaadi
      </a>{' '}
      back then), at first I said NO but then who would miss the opportunity to work with them, I said FUCK YEAH!.
    </div>

    <div className="py-2">
      Currently, I work as Associate Director of Engineering and actively participate in product, design and engineering for{' '}
      <a className="font-semibold text-yellow text-base" href="https://www.sangam.com" target="_blank" rel="noreferrer">
        Sangam.com
      </a>
      .{' '}
    </div>

    <div className="py-2">
      In my spare time, I meditate, ride bikes, cook delicious food, travel. I have a thing for photography (check out my{' '}
      <a className="font-semibold text-yellow text-base" href="https://instagram.com/shivamsupr" target="_blank" rel="noreferrer">
        Instagram
        <span role="img" aria-label="instagram">
          📷
        </span>
      </a>
      ). I love playing Cricket, board games and indoor games. I&apos;m trying to learn a musical instrument. Also, I love to spend time
      with family and friends.{' '}
    </div>

    <div className="py-2">
      Wherever I&apos;m today and whatever little I have achieved till now, I&apos;ll always be grateful for all the people who supported me
      throughout my life.
      <span role="img" aria-label="heart">
        ❤️
      </span>
    </div>

    <div className="py-2">PS: I do not work on weekends.</div>
  </div>
);
