import React from 'react';
import EventListener from 'react-event-listener';
import CustomTerminal from '../common/terminal';
import errorText from './errorText';
import commands from './commands';
import WelcomeMessage from '../common/WelcomeMessage';
import {isMobile} from "../../helpers/deviceDetect";

export default class FolioDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.terminal = React.createRef();
  }

  handleKeyboardShortCuts = e => {
    const terminal = this.terminal.current;
    // clear terminal
    if ((e.key === 'l' || e.key === 'L') && e.ctrlKey) {
      terminal.clearStdout();
    }
    // clear input
    if ((e.key === 'u' || e.key === 'U') && e.ctrlKey) {
      terminal.clearInput();
    }
  };

  render() {
    return (
      <div className="border-t-8 border-pacifika_light w-full md:w-11/12 xl:w-2/3 mx-auto">
        <EventListener target="document" onKeyDown={this.handleKeyboardShortCuts} />
        <CustomTerminal
          setRef={this.terminal}
          welcomeMessage={<WelcomeMessage terminalRef={this.terminal} />}
          errorText={errorText()}
          commands={commands(this.terminal)}
          autoFocus={!isMobile}
          noDefaults
          ignoreCommandCase
          noEchoBack
          hidePromptWhenDisabled
          disableOnProcess
          className="folio-main"
          contentClassName="folio-content folio-text-leafy_seadragon"
          promptLabelClassName="folio-text-yellow text-base"
          inputClassName="folio-text-yellow folio-h-auto"
        />
      </div>
    );
  }
}
