export default {
  ABOUT: 'about me!',
  SPECIALIZATION: 'what do I specialize in?',
  SKILLS: 'What are my skills?',
  PROJECTS: 'What are the projects I worked/working on?',
  RECOMMENDATIONS: 'What do my peers say about me?',
  TIMELINE: 'Education and work details',
  CONTACT: 'How can you reach me?',
  HELP: 'How can I help?',
  CLEAR: 'Clear the terminal screen',
};
