/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Terminal from 'react-console-emulator';

export default props => {
  const termProps = { ...props };
  const { setRef } = termProps;

  return <Terminal ref={setRef} {...props} />;
};
