import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const { allSkillsJson } = useStaticQuery(graphql`
    {
      allSkillsJson {
        edges {
          node {
            frontend {
              HTML
              CSS
              JavaScript
            }
            backend
            database
            devops
            othertools
          }
        }
      }
    }
  `);

  const { frontend, backend, database, devops, othertools } = allSkillsJson.edges[0].node;
  const { CSS: css, JavaScript: js } = frontend;

  return (
    <div className="text-sm">
      <ul className="unix-tree">
        <li className="unix-tree-root pt-4 pb-1 text-xl font-medium uppercase">Skills</li>
        <li className="unix-tree-node">
          <span className="py-1 text-base text-yellow font-semibold">Front-end</span>
          <ul className="unix-tree">
            <li className="unix-tree-node">HTML</li>
            <li className="unix-tree-node">
              CSS
              <ul className="unix-tree">
                {css.map(lib => (
                  <li key={lib} className="unix-tree-node">
                    {lib}
                  </li>
                ))}
              </ul>
            </li>
            <li className="unix-tree-node">
              JavaScript
              <ul className="unix-tree">
                {js.map(lib => (
                  <li key={lib} className="unix-tree-node">
                    {lib}
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </li>

        <li className="unix-tree-node">
          <span className="py-1 text-base text-yellow font-semibold">Back-end</span>
          <ul className="unix-tree">
            {backend.map(lib => (
              <li key={lib} className="unix-tree-node">
                {lib}
              </li>
            ))}
          </ul>
        </li>

        <li className="unix-tree-node">
          <span className="py-1 text-base text-yellow font-semibold">Database</span>
          <ul className="unix-tree">
            {database.map(lib => (
              <li key={lib} className="unix-tree-node">
                {lib}
              </li>
            ))}
          </ul>
        </li>

        <li className="unix-tree-node">
          <span className="py-1 text-base text-yellow font-semibold">DevOps</span>
          <ul className="unix-tree">
            {devops.map(lib => (
              <li key={lib} className="unix-tree-node">
                {lib}
              </li>
            ))}
          </ul>
        </li>

        <li className="unix-tree-node">
          <span className="py-1 text-base text-yellow font-semibold">Other Tools</span>
          <ul className="unix-tree">
            {othertools.map(lib => (
              <li key={lib} className="unix-tree-node">
                {lib}
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};
