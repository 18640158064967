import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { isMobile } from '../../helpers/deviceDetect';

const InfoImage = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(childImageSharp: { fixed: { originalName: { eq: "me-245.jpg" } } }) {
        childImageSharp {
          fixed(width: 245, height: 381) {
            ...GatsbyImageSharpFixed
            ...GatsbyImageSharpFixed_withWebp
            width
            height
          }
        }
      }
      desktopImage: file(childImageSharp: { fixed: { originalName: { eq: "me.jpg" } } }) {
        childImageSharp {
          fixed(width: 300, height: 466) {
            ...GatsbyImageSharpFixed
            ...GatsbyImageSharpFixed_withWebp
            width
            height
          }
        }
      }
    }
  `);

  const { mobileImage, desktopImage } = data;
  const { srcWebp: mobileImg, src: mobileOriginalImg, width: mobileWidth, height: mobileHeight } = mobileImage.childImageSharp.fixed;
  const { srcWebp: desktopImg, src: desktopOriginalImg, width: desktopWidth, height: desktopHeight } = desktopImage.childImageSharp.fixed;

  return (
    <div className="mx-auto">
      {(isMobile && (
        <picture>
          <source type="image/webp" srcSet={mobileImg} />
          <source type="image/jpeg" srcSet={mobileOriginalImg} />
          <img loading="lazy" src={mobileOriginalImg} alt="shivamsupr" width={mobileWidth} height={mobileHeight} />
        </picture>
      )) || (
        <picture>
          <source type="image/webp" srcSet={desktopImg} />
          <source type="image/jpeg" srcSet={desktopOriginalImg} />
          <img loading="lazy" src={desktopOriginalImg} alt="shivamsupr" width={desktopWidth} height={desktopHeight} />
        </picture>
      )}
    </div>
  );
};

export default InfoImage;
