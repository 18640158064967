import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const { allProjectsJson } = useStaticQuery(graphql`
    {
      allProjectsJson {
        edges {
          node {
            fulltime
            consulting
            independent
          }
        }
      }
    }
  `);

  const { fulltime, consulting, independent } = allProjectsJson.edges[0].node;

  return (
    <div className="text-sm">
      <div className="py-4 text-xl font-medium uppercase">Projects</div>

      <div className="pb-2">
        <div className="py-1 text-base text-yellow font-semibold">Full-time</div>
        {fulltime.map(ft => (
          <div key={ft} className="pl-5">
            *{' '}
            <a href={`https://www.${ft}`} className="text-golden_gun" target="_blank" rel="noreferrer nofollow">
              {ft}
            </a>
          </div>
        ))}
      </div>

      <div className="py-2">
        <div className="py-1 text-base text-yellow font-semibold">Consulting</div>
        {consulting.map(ct => (
          <div key={ct} className="pl-5">
            *{' '}
            <a href={`https://www.${ct}`} className="text-golden_gun" target="_blank" rel="noreferrer nofollow">
              {ct}
            </a>
          </div>
        ))}
      </div>

      <div className="py-2">
        <div className="py-1 text-base text-yellow font-semibold">Independent</div>
        {independent.map(idp => (
          <div key={idp} className="pl-5 text-golden_gun">
            *{' '}
            {(idp === 'ladoo.co' && (
              <a href={`https://www.${idp}`} className="text-golden_gun" target="_blank" rel="noreferrer nofollow">
                {idp}
              </a>
            )) ||
              idp}
          </div>
        ))}
      </div>
    </div>
  );
};
