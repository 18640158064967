import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const { allRecommendationsJson } = useStaticQuery(graphql`
    {
      allRecommendationsJson {
        edges {
          node {
            name
            image_url
            linkedin_profile_url
            title
            message
          }
        }
      }
    }
  `);

  return (
    <div className="text-sm">
      <div className="py-4 text-xl font-medium uppercase">Recommendations</div>

      {allRecommendationsJson.edges.map((edge, index) => {
        const { node } = edge;
        const { name, image_url: imageURL, linkedin_profile_url: profileURL, title, message } = node;

        return (
          <div key={name} className="px-2">
            <a href={profileURL} target="_blank" rel="nofollow noreferrer">
              <div className="pb-3 flex flex-row">
                <div className="w-2/12 sm:w-10 mx-2 mt-1.5">
                  <img src={imageURL} alt={name} className="rounded-3xl" />
                </div>

                <div className="w-10/12 flex flex-col">
                  <div className="text-lg">{name}</div>
                  <div className="text-sm text-leafy_seadragon">{title}</div>
                </div>
              </div>
            </a>

            <div className="whitespace-pre-line tracking-wider">{message}</div>

            {(index !== allRecommendationsJson.edges.length - 1 && <hr className="w-3/5 my-6 lg:my-4 sm:my-6 mx-auto" />) || <br />}
          </div>
        );
      })}
    </div>
  );
};
