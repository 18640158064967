import React, { useEffect } from 'react';

export default props => {
  const termProps = { ...props };
  const { terminalRef } = termProps;

  useEffect(() => {
    terminalRef.current.clearStdout();
  }, [terminalRef]);

  return <div>&nbsp;</div>;
};
