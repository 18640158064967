import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Info from '../components/info';
import FolioDetails from '../components/folioDetails';

const IndexPage = () => (
  <Layout>
    <SEO title="Shivam Rastogi" />

    <Info />
    <FolioDetails />
  </Layout>
);

export default IndexPage;
